import React from 'react';
import Loadable from 'react-loadable';
import {
  ClassnamePartial,
  EnvironmentPartial,
  QaIdPartial,
  CodeSnippet,
  Link,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Overlay = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Overlay;
    return <Component {...props} />;
  },
});

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const KeyboardShortcut = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.KeyboardShortcut;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/overlay.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Overlay"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/overlays-dialogs--docs#overlay"
      />
      <CodeSnippet
        scope={{ React: React, Overlay: Overlay, Button: Button }}
        code={snippet}
        platform="react"
        gitHubLink="modal"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']}>
            <Text>The content that is displayed in the overlay.</Text>
          </PropListItem>

          <ClassnamePartial componentName="overlay" />

          <EnvironmentPartial />

          <PropListItem name="header" types={['string']}>
            <Text>
              What is displayed in the header as the overlay title. Will also
              show the Dismiss button if provided.
            </Text>
          </PropListItem>

          <PropListItem name="isOpen" types={['boolean']} isRequired>
            <Text>Determines whether or not the overlay is displayed.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Functional">
          <PropListItem name="allowCloseOnEsc" types={['boolean']}>
            <Text>
              Determines whether or not the user can hit{' '}
              <KeyboardShortcut>esc</KeyboardShortcut> to close the overlay.
            </Text>
          </PropListItem>

          <PropListItem name="allowCloseOnOverlayClick" types={['boolean']}>
            <Text>
              Determines whether or not the user can click the scrim to close
              the overlay.
            </Text>
          </PropListItem>

          <PropListItem name="parentSelector" types={['() => HTMLElement']}>
            <Text>
              Called to get the parent element that the overlay will be attached
              to. By default, it will be attached to the <code>body</code> tag.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onAfterOpen" types={['() => void']}>
            <Text>Called when the modal is opened.</Text>
          </PropListItem>

          <PropListItem name="onClose" types={['() => void']}>
            <Text>
              Function called when the modal is triggered to close. This is
              called if and when the <KeyboardShortcut>esc</KeyboardShortcut>{' '}
              key is pressed, the overlay is clicked, the (optional) Cancel
              button is clicked, or the X is clicked, triggering the modal to
              close.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <PropListItem name="label" types={['string']}>
            <Text>
              Value used for the aria-label accessibility tag on the overlay
              element. Defaults to "Hudl Overlay".
            </Text>
          </PropListItem>

          <QaIdPartial
            componentName="overlay"
            passedDown={['header', 'dismiss']}
          />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from the{' '}
              <Link href="http://reactcommunity.org/react-modal/">
                React-Modal
              </Link>{' '}
              component.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
